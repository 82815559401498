@import "../../../assets/style/styles";

:root {
  --label-background: #ffffff;
  --embed-background: #e8e8e8;
  --border-color: #dbdbdb;
  --logo-first-color: #5250a2;
  --text-color: #4d4d4d;
}

[data-theme="gray"] {
  --label-background: #cbcbcb;
  --embed-background: #b8b8b8;
  --border-color: #939393;
  --logo-first-color: #5250a2;
  --text-color: #4d4d4d;
}

[data-theme="dark"] {
  --label-background: #1f1f22;
  --embed-background: #222225;
  --border-color: #292929;
  --logo-first-color: #d6d5f5;
  --text-color: #4d4d4d;
}

.Embed {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: 2px solid var(--border-color);
  background: var(--embed-background);
  font-family: "Roboto";

  .app-label {
    background: var(--label-background);
    text-decoration: none;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    box-sizing: border-box;
    border-bottom: 2px solid var(--border-color);

    .Logo {
      h1 {
        .first {
          color: var(--logo-first-color);
        }
      }
    }
  }

  .embed-button {
    background: #4045a5;
    padding: 4px 6px;
    margin-right: 10px;
  }

  .footer {
    width: 100%;
    height: 15px;
    background: #333333;
    color: #a19dbf;
    font-size: 8px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-family: "Roboto";
    letter-spacing: 0.5px;
  }

  .starGrade {
    font-size: 5px;
  }

  .error {
    padding: 12px;
    flex: 1;
    overflow: hidden;

    .icon-attention-circled {
      font-size: 25px;
    }

    p {
      font-size: 14px;
    }
  }
}

@media only screen and (max-height: 151px) {
  .Embed {
    .app-label {
      height: 35px;
      .Logo {
        .LogoIcon {
          .MonitorIcon {
            width: 18px;
            height: 18px;

            .screen {
              span {
                font-size: 8px;
              }
            }
          }
        }
        h1 {
          align-items: center;
          flex-direction: row;

          .second {
            font-size: 16px;
            display: flex;

            span {
              transform: translateY(-2px);
            }
          }
        }
      }
    }
  }
}
