.Button {
  border: none;
  background: transparent;
  color: #aaa;
  font-family: Roboto;
  text-transform: uppercase;
  flex: 1;
  border-color: red;
  padding: 8px;
  transition: 0.15s ease-out;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.border-top {
    border-top: 1px solid #898989;
  }

  &.border-bottom {
    border-bottom: 1px solid #898989;
  }

  &:hover {
    color: #bbb;
    cursor: pointer;
  }

  &:active {
    color: #ccc;
    background: rgba(20, 20, 20, 0.1);
  }

  &:focus {
    outline: none;
  }
}
