@import "../../../assets/style/styles";

.schoolBackground {
  @extend .image;
  @extend .middle;
  overflow-anchor: none;
  width: 100%;
  height: 150px;
  border-radius: 8px;
  margin-bottom: 15px;
  overflow: hidden;
  position: relative;

  & > div {
    position: absolute;
  }

  & > .controlls {
    top: 0;
    right: 0;
    z-index: 100;

    & > i {
      background: #373737;
      margin: 8px;
      width: 40px;
      color: white;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #3c3c3c;
      box-sizing: border-box;
      transition: 0.2s ease-out;

      &:hover {
        background: #444448;
        cursor: pointer;
      }
    }
  }

  & > .background {
    position: absolute;
    @extend .image;
    width: 100%;
    height: 150px;
    box-shadow: inset 0 0 5px 1px #222;
    border-radius: 8px;
  }

  & > .foreground {
  }
}
