@import "../../../assets/style/styles";

.WelcomeAddSchool {
  display: flex;
  flex: 2 2;

  .content {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .icon {
      width: 100%;
      height: 170px;
      @extend .image;
      border-radius: 15px;
      margin-bottom: 20px;
    }

    h1 {
    }
    p {
      text-align: left;
      line-height: 24px;
      font-family: "Nunito";
    }
  }
}
