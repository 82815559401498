@import "../../../assets/style/styles";

.Rules {
  flex: 1;
  display: flex;

  .main {
    display: flex;

    flex: 1;

    & > div {
      flex: 1;
    }
  }

  .welcome {
    .content {
      flex: 1;
      text-align: left;
      line-height: 24px;
      .icon {
        width: 100%;
        height: 200px;
        @extend .image;
        border-radius: 15px;
        margin-bottom: 20px;
      }
      p {
        font-family: "Nunito";
      }
    }
  }

  .info {
    .content {
      flex: 1;
      .top {
        display: flex;
        flex: 1;
        justify-content: space-between;
        text-align: left;
        .title-box {
          flex: 1;
          display: flex;
          align-items: center;

          span {
            font-size: 14px;
            text-transform: uppercase;
            font-weight: bold;
            color: #a5a5a5;
          }

          i {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35px;
            height: 35px;
            background: #4771d7;
            color: #eee;
            border-radius: 10px;
            margin-right: 10px;
            font-size: 20px;
          }
        }
      }

      .bottom {
        display: flex;
        margin-top: 10px;
        padding-top: 10px;
        box-sizing: border-box;
        border-top: 1px solid;
        max-height: 0;
        opacity: 0;
        & > div {
          flex: 1;
          max-height: 350px;
          overflow-y: scroll;
          scroll-padding-bottom: 0;
          scroll-margin-bottom: 0;
          position: relative;
          & > div {
            text-align: left;
            span,
            li {
              color: #aaa;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.PageRegulation {
  ol {
    margin-bottom: 10px;
  }
  & > ol {
    list-style-type: none;
    padding-left: 0;
    margin-left: 0;

    & > li {
      & > ol {
        & > li {
          & > ol {
            list-style-type: lower-alpha;
          }
        }
      }
    }
  }
}

.PrivacyPolicy {
  & > ol {
    margin-left: 0;
    padding-left: 20px;
    list-style-type: upper-roman;
    & > li {
      margin-bottom: 10px;
    }

    & > ol {
      & > ol {
        list-style-type: lower-alpha;
      }
    }
  }
}
