@import "../../../assets/style/styles";

.SchoolDetailsGoogleReview,
.schoolDetailsUserComment {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #404040;
  padding: 10px;
  width: 347px;
  transition: 0.25s ease-out;
  &:hover {
    cursor: pointer;
    background: #262728;
  }

  .comment-content {
    width: calc(100% - 30px);
    text-align: left;
    box-sizing: border-box;
    padding-right: 10px;

    .name-and-date {
      @extend .hideable;

      .name {
        color: $name-color;
        font-size: 12.5px;
        font-weight: bold;
        margin-right: 7px;
        text-transform: capitalize;
      }

      .date {
        font-size: 10.5px;
        color: #888;
        margin-right: 5px;
      }
    }
    .google-label {
      font-size: 10.5px;
      color: #777;
    }

    .comment {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      max-height: 50px;
      overflow: hidden;
      white-space: wrap;
      text-overflow: ellipsis;
      line-height: 16px;

      span {
        font-size: 13.5px;
        color: #898b97;
      }
    }
  }

  .comment-icon {
    @extend .middle;
    width: 25px;
    height: 25px;
    background-color: #888;
    border-radius: 5px;

    &.up {
      background: linear-gradient(45deg, #45b14f, #61ba17);
      background-color: #78aa56;
    }

    &.down {
      background: linear-gradient(45deg, #af3939, #d55f5f);
      background-color: #db6969;
    }

    .icon {
      color: white;
      text-shadow: 0 0 2px black;
    }
  }
}
