@import "../../../assets/style/styles";

.schoolDetailsBasicInformations {
  .distinguishing-feature {
    padding: 5px 0;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #505050;
    background: #824dff;
    color: white;
    filter: brightness(0.85);

    .icon-box {
      @extend .middle;
      width: 50px;
      height: 40px;
      border-radius: 5px;

      i {
        font-size: 25px;
      }
    }

    .value-box {
      flex: 1;
      padding-right: 10px;
      text-align: left;
      line-height: 14px;

      span {
        font-size: 13px;
      }
    }
  }

  .basic-information {
    display: flex;
    height: 30px;
    list-style-type: none;
    align-items: center;
    border-bottom: 1px solid #505050;

    &.additional-locations {
      height: auto;

      .value {
        margin: 3px 0;
        -webkit-line-clamp: 5;
      }

      ul {
        margin: 0;
        padding-left: 15px;

        li {
          margin: 0;
          padding: 0;
        }
      }
    }

    li {
      list-style-type: initial;
    }

    &:last-child {
      border-bottom: none;
    }

    .basic-information-link {
      opacity: 1;
      filter: grayscale(0.5);

      &:hover {
        filter: grayscale(0);
      }
    }

    .icon {
      width: 13.5px;
      font-size: 13.5px;
      margin-right: 8px;
    }

    .property-name {
      @extend .hideable;
      flex: 1;
      font-size: 13.5px;
      text-align: left;
      color: #999;
    }

    .value {
      @extend .hideable;
      flex: 2;
      font-size: 13.5px;
      text-align: left;
      padding: 0 8px;
      box-sizing: border-box;
      color: #aaa;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      max-height: 90px;

      &.undefined {
        color: #888;
        font-style: italic;
      }
    }
  }

  .margin {
    margin: 20px 0;
  }
}
