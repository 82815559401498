@import "../../../assets/style/styles";

.schoolDetailsVoteCloudMailVerificationStage {
  flex: 1;
  display: flex;
  flex-direction: column;

  .body {
    flex: 1;
    display: flex;

    .vote-icon {
      display: flex;
    }

    .form {
      width: 0;
      flex: 1;
      .waiting-bar {
        display: flex;
        margin-right: 5px;
        width: 22px;
        height: 22px;
      }

      .info {
        font-family: "Nunito";
      }
    }

    .input-area {
      text-align: left;
    }
  }

  .footer {
    flex: 1;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #686868;
    padding: 3px;
    padding-top: 10px;
  }
}
