.SchoolDetailsVoteIcon {
  display: flex;
  align-items: center;

  .icons {
    position: relative;
    margin-right: 15px;

    .vote-type-icon {
      font-size: 50px;
    }
  }
}
