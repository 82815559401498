@import "../../../assets/style/styles";

.Frame {
  position: relative;

  & > .content-wrapper {
    overflow-y: scroll;
    overflow-y: overlay;
    scroll-behavior: smooth;

    & > .content {
      display: flex;
      flex-direction: column;
      min-height: 100%;
    }
  }

  & > .scroll-button-wrapper {
    @extend .middle;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 500;

    .scroll-button {
      @extend .middle;
      width: 32px;
      height: 32px;
      border: none;
      background-color: #aaa;
      border-radius: 50%;
      transition: 0.25s ease-out;
      opacity: 0.6;
      position: absolute;
      bottom: 5px;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
        background-color: #ccc;
      }

      &:active {
        opacity: 1;
        background-color: #cccccc;
      }

      &:focus {
        outline: none;
      }
      .icon {
        color: #212223;
        font-size: 16px;
      }
    }
  }

  & > .shadow {
    position: absolute;
    left: 0;
    width: 100%;
    height: 30px;
    transition: opacity 0.2s ease-out;
    z-index: 100;
  }

  & > .up-shadow {
    top: 0;
    background: linear-gradient(to bottom, #424242, transparent);
  }

  & > .down-shadow {
    bottom: 0;
    background: linear-gradient(to top, #424242, transparent);
  }
}
