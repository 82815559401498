.Ranking {
  .top {
    display: flex;
    flex: 2;
  }

  .bottom {
    display: flex;
    flex: 5;
  }

  .ranking-area {
    width: 0;
  }
}
