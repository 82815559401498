.ScoreBox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #555;
  max-height: 65px;
  overflow: hidden;
  &:last-child {
    border-bottom: none;
  }
  .score-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 40px;
    margin-right: 10px;
    .count {
      color: white;
      text-shadow: 0 0 2px #222;
      font-size: 16px;
      font-weight: bold;
      position: absolute;
      z-index: 10;
    }
    .score {
      width: 100%;
      height: 100%;
      border-radius: 3px;
      background: #3f44a6;
    }
  }

  .score-text {
    width: calc(100% - 70px);
    text-align: left;
    font-size: 13px;

    .score-title {
      font-weight: bold;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .score-description {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      max-height: 35px;
      overflow: hidden;
    }
  }
}
