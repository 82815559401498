@import "../../../assets/style/styles";

.SchoolDetailsVoteCloud {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;

  & > .header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #686868;
    padding: 3px;
    padding-bottom: 10px;
    margin-bottom: 10px;

    h3 {
      @extend .hideable;
    }

    .status {
      @extend .middle;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 7px;

      .status-icon {
        color: white;
        font-size: 10px;
      }
    }
  }

  & > .content {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;

    .info {
      color: #959595;
      margin: 0;
      font-size: 15px;
    }
  }
}
