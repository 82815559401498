.starGrade {
  font-size: 11px;
  white-space: nowrap;
  text-overflow: ellipsis;
  i {
    font-size: inherit;

    &.icon-star {
      color: gold;
    }

    &.icon-star-half-alt,
    &.icon-star-half {
      color: gold;
    }
  }
}
