.CookieAlert {
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 5px 50px;
  background: rgba(46, 44, 59, 0.65);
  border-top: 1px solid rgba(63, 63, 63, 0.7);
  width: 100%;
  z-index: 10000;
  box-sizing: border-box;

  span {
    color: #bbb;
    font-size: 12px;
  }

  button {
    border: none;
    background: #3b445a;
    margin: 5px;
    color: #333;
    border-radius: 2px;
    font-size: 11px;
    color: #eee;
    padding: 5px 8px;
    opacity: 0.8;
    transition: 0.25s ease-out;

    &:hover {
      cursor: pointer;
      opacity: 1;
    }

    &.light {
      background: #556a9f;
      font-weight: bold;
      color: #eee;
    }

    &.close {
      position: absolute;
      right: 0;
      top: 0;
      background: none;
      color: #bbb;
      font-size: 20px;
    }
  }
}
