@import "../../../assets/style/styles";

.Footer {
  @extend .middle;
  display: flex;
  padding: 30px 0;

  .bar {
    @extend .middle;
    width: 80%;
    min-height: 40px;
    background: $footer-bar-background;
    border-radius: 35px;
    color: #c3c3c3;
    font-family: "Roboto";
    padding: 10px;

    .photo {
      @extend .middle;
      width: 40px;
      height: 40px;
      background: #bdbde4;
      border-radius: 50%;

      position: relative;

      i {
        color: #333773;
        font-size: 20px;
      }

      .status {
        position: absolute;
        width: 8px;
        height: 8px;
        bottom: 0;
        right: 0;
        border-radius: 50%;
        border: 1px solid #373e50;

        &.online {
          background: #7777ac;
          animation: 0.9s infinite highlight_status_online;

          @keyframes highlight_status_online {
            0% {
              background: #7777ac;
            }
            20% {
              background: #938fff;
            }
          }
        }

        &.offline {
          background: #ee3d32;
          animation: 0.9s infinite highlight_status_offline;

          @keyframes highlight_status_offline {
            0% {
              background: #ee3d32;
            }
            50% {
              background: #f2675e;
            }
          }
        }
      }
    }

    .content {
      @extend .hideable;
      width: calc(100% - 50px);
      height: 100%;
      font-family: "Nunito";
      text-align: left;
      margin-left: 10px;

      .main {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        color: #b2b5ef;
      }

      .sub {
        font-size: 11px;
        color: #b8b6ce;

        .role {
        }

        .ip {
          font-size: 11px;
        }
      }
    }
  }
}
