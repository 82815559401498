.SchoolDetailsVoteCloudRecaptchaStage {
  display: block;

  .info {
    text-align: left;
    display: flex;

    .google-label {
      margin-top: 10px;
      line-height: 12px;
      span {
        font-size: 11px;
      }
    }
  }
}
