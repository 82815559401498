.Oops {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .MonitorIcon {
    .screen {
      background: #474747;

      span {
        font-family: "Roboto";
        color: #9a9a9a;
        font-size: 16px !important;
        text-transform: uppercase;
        letter-spacing: 1.5px;
      }
    }
  }

  .message {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #6f6f6f;
    font-family: "Nunito";
    font-size: 14px;
  }
}
