@import "../../../assets/style/styles";

.addSchool {
  .top {
    display: flex;
    flex: 3;

    & > div {
      flex: 2;
    }
  }

  .bottom {
  }
}
