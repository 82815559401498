@import "../../../assets/style/styles";

.SchoolDetails-wrapper {
  width: 458px;
  position: relative;

  .SchoolDetails {
    position: relative;
    transition: top 0.15s ease-out;

    &.fixed-on-side {
      top: 0;
      position: fixed;
    }

    .motion-bubble {
      height: 96vh;
      box-sizing: border-box;
      overflow: visible;
    }

    .inside {
      width: 380px;
      height: 100%;

      .top-side {
        width: 100%;
        height: 64px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;

        .hide-icon {
          @extend .middle;
          display: none;
          width: 64px;
          height: 64px;
          border-radius: 50%;
          position: absolute;
          opacity: 0.6;
          top: 16px;
          right: 16px;
          z-index: 10;
          color: #7d7d7d;

          i {
            font-size: 40px;
          }
        }
      }

      .left-side {
        .hide-icon {
          @extend .middle;
          display: none;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          border: 1px solid;
          position: absolute;
          z-index: 100;
          left: -16px;
          top: calc(50% - 16px);
          background: #aaa;
          opacity: 0.6;
          i {
            color: #212223;
            font-size: 16px;
          }
        }
      }

      .middle-side {
        height: 100%;
        .schoolDetails {
          overflow-anchor: none;
          display: flex;
          flex-direction: column;
          height: 100%;
          position: relative;

          .title {
            @extend .hideable;
            text-align: center;
          }

          .grade {
          }

          & > .details {
            flex: 1;
            display: flex;
            flex-direction: column;

            .section-options {
              display: flex;
              .button {
                font-size: 12.5px;
                i {
                  margin-right: 2px;
                }
              }
            }

            .content {
              flex: 1;
            }
          }

          .voter {
            display: flex;
          }
        }
      }
    }
  }
}
