@import "../../../assets/style/styles";

.Cloud {
  position: absolute;
  bottom: 70px;
  z-index: 1000;
  background: $cloud-background;
  box-shadow: 0 0 18px 5px #191919;
  border-radius: 15px;
  width: 100%;
  min-height: 150px;
  box-sizing: border-box;
  display: flex;
  overflow: hidden;

  & > .close {
    width: 32px;
    height: 32px;
    @extend .middle;
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 100;
    transition: 0.2s ease-out;
    background-color: transparent;
    border: none;
    color: #888;

    i {
      font-size: 17px;
    }

    &:hover {
      cursor: pointer;
      color: #aaa;
    }
  }

  & > .content {
    flex: 1;
    padding: 15px;
  }
}
