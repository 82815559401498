@import "../../../assets/style/styles";

.schoolDetailsExpertOpinion {
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid;
  width: 347px;

  &:last-child {
    border-bottom: none;
  }

  .expert-photo {
    @extend .image;
    @extend .middle;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #33333c;
    &.pending {
      i {
        color: #aaa;
      }
    }
    i {
      color: #333773;
      font-size: 20px;
    }
  }

  .expert-content {
    max-width: calc(100% - 46px);
    flex: 1;
    padding: 0 10px;
    box-sizing: border-box;

    span {
      font-family: Roboto;
    }

    .name-and-grade,
    .title {
      text-align: left !important;
    }

    .name-and-grade {
      @extend .hideable;
      color: $name-color;
      font-size: 13px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      font-family: Roboto;

      .name {
        .css-1vmnjpn-skeletonStyles-Skeleton {
          margin-right: 5px;
        }
      }

      .grade-box {
        .grade {
          font-size: 9px;
        }
      }
    }

    .title {
      @extend .hideable;
      color: #888;
      font-size: 10px;
    }

    .comment {
      text-align: left;
      font-size: 13.5px;
      color: $comment-color;

      &.extension-text {
        margin-top: 7px;
      }
    }
  }
}
