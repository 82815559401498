@import "../../../assets/style/styles";

.Top {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 45px;
  background: $menu-background;
  overflow: hidden;
  box-shadow: 0 10px 10px 1px #1b1b1b;

  .logo {
    .Logo {
      transform: scale(0.7);
    }
  }
}
