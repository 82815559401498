.RankSearch {
  .rank-search-inner {
    width: 100%;
  }

  .info {
    font-size: 12px;
  }

  .rankSearch {
    display: flex;
    align-items: center;
    flex: 1;

    .icon-search {
      margin-right: 5px;
      transform: translateY(-2px);
    }

    .input-wrapper {
      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-align: left;
      border-right: 1px solid #3e3f41;

      .input-wrapper-top {
        display: flex;
      }

      .rank-search-input {
        background: transparent;
        flex: 1;
        border: none;
        color: #bbb;
        font-size: 16px;
        font-family: Roboto;

        &:focus {
          outline: none;
        }

        &:disabled,
        &.disabled {
          color: #999;
        }
      }
    }

    .rank-search-filter {
      //appearance: none;
      flex: 1;
      outline: none;
      background-color: transparent;
      border: none;
      color: #bbb;
      font-size: 14px;
      font-family: Roboto;
      padding: 5px 20px;
      &:focus {
        outline: none;
      }

      option {
        margin: 40px;
        background: #212223;
        color: #c6c6c6;
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
      }
    }
  }
}
