@import "../../../assets/style/styles";

$place-flex: none;
$score-flex: none;
$name-flex: 4;
$city-flex: 2;
$stars-flex: 2;
$votes-up-flex: 1;
$votes-down-flex: 1;

.schoolPreview-wrapper {
  text-decoration: none;
  color: inherit;

  &:last-child {
    .schoolPreview {
      border-bottom: none;
    }
  }
  .schoolPreview {
    display: flex;
    border-bottom: 1px solid #303030;
    border-radius: 7px;
    padding: 14px 0;
    box-sizing: border-box;
    height: 35px;
    align-items: center;
    transition: 0.15s ease-out;

    &:hover {
      cursor: pointer;
      background: #282828;
    }

    &.selected {
      background-color: rgba(255, 255, 255, 0.05);
    }

    & > div {
      flex: 1;
      overflow: hidden;
      position: relative;
      white-space: nowrap;
      text-overflow: ellipsis;

      span {
        font-size: 12px;
        color: #aaa;
        font-family: Roboto;
      }
    }

    .place {
      flex: $place-flex;
      width: 20px;
      height: 20px;
      background: #6a92f6;
      color: white;
      border-radius: 50%;
      margin-right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.85;

      &.podium {
        opacity: 1;
      }

      span {
        font-size: 10px;
        color: white;
        font-weight: bold;
      }
    }

    .score {
      flex: $score-flex;
      width: 42px;
      height: 22px;
      background: hsl(237deg, 44%, 45%);
      color: white;
      padding: 0 8px;
      box-sizing: border-box;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
        color: #eee;
      }
    }

    .name {
      flex: $name-flex $name-flex;
      text-align: left;
      padding: 0 10px;
      width: 0;
      text-transform: uppercase;
    }

    .city {
      flex: $city-flex $city-flex;
    }

    .stars {
      flex: $stars-flex $stars-flex;
    }

    .votes-up {
      flex: $votes-up-flex $votes-up-flex;
      text-align: right;
      padding: 0 3px;

      span {
        color: #93cc93;
        font-weight: bold;
      }
    }

    .votes-down {
      flex: $votes-down-flex $votes-down-flex;
      text-align: right;
      padding: 0 3px;

      span {
        color: #ff9b8e;
        font-weight: bold;
      }
    }
  }
}
