@import "../../../assets/style/styles";

.bubble {
  margin: 15px;
  position: relative;
  display: flex;

  .motion-bubble {
    flex: 1;
    background: $bubble-background;
    padding: 25px;
    box-shadow: 0 0 10px 2px #171819;
    overflow: hidden;
    border-radius: 15px;
    display: flex;

    .motion-bubble-inner {
      flex: 1;
      display: flex;
    }
  }
}
