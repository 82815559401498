@import "../../../assets/style/styles";

.EmbedSchool {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 15px;

  .school-top {
    display: flex;
  }
  .school-logo {
    @extend .image;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
  }

  .school-label {
    flex: 1;

    .school-name {
      margin: 0;
      font-size: 4vw;
      line-height: 4.5vw;
    }

    .school-city {
      margin: 0;
      margin-top: 0.5vw;
      font-size: 3vw;
      font-weight: normal;
    }
  }

  .school-bottom {
    margin-top: 10px;
    .school-stats {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .stat {
        flex: 1;
        background: #4045a5;
        padding: 4px 6px;
        border-radius: 3px;
        height: 40px;
        box-sizing: border-box;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }

        .left-stat {
          width: 50%;
          display: flex;
          align-items: center;

          i {
            color: #ddd;
            border-right: 1px solid #9094e4;
            padding-right: 5px;
            font-size: 20px;
          }
        }

        .right-stat {
          width: 50%;
          display: flex;
          align-items: center;
          flex-direction: column;
        }

        span {
          color: #ddd;
          text-transform: uppercase;
          font-size: 8px;

          font-family: "Roboto";

          &.value {
            font-size: 18px;
            font-weight: bold;
          }
        }
      }
    }

    .comments-previews {
      .comment {
        height: 60px;
        margin-bottom: 5px;
        border-bottom: 1px solid #333;
        overflow: hidden;

        span {
          font-size: 13px;
        }

        .author {
          font-size: 12px;
          font-weight: bold;
        }

        .content {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          max-height: 50px;
          overflow: hidden;
          white-space: wrap;
          text-overflow: ellipsis;
          line-height: 16px;
        }
      }
    }
  }
}
