.schoolDetailsVoteCloudSuccessStage {
  flex: 1;
  display: flex;
  flex-direction: column;

  .body {
    flex: 1;
    display: flex;
    margin-bottom: 10px;

    .vote-icon {
      display: flex;
    }

    .form {
      width: 0;
      flex: 1;
      display: flex;
      align-items: center;
      .waiting-bar {
        display: flex;
        margin-right: 5px;
        width: 22px;
        height: 22px;
      }
    }
  }

  .footer {
    flex: 1;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #686868;
    padding: 3px;
    padding-top: 10px;

    .link-button {
      display: flex;

      .app-button {
        flex: 1;
      }
    }
  }
}
