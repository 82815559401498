.IconPending {
  display: inline-block;

  & > i {
    display: inline-block;
    animation: 1s spinner infinite;
    animation-timing-function: linear;
    margin-right: 5px;
  }
}
