@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?85936080');
  src: url('../font/fontello.eot?85936080#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?85936080') format('woff2'),
       url('../font/fontello.woff?85936080') format('woff'),
       url('../font/fontello.ttf?85936080') format('truetype'),
       url('../font/fontello.svg?85936080#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?85936080#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-play:before { content: '\e800'; } /* '' */
.icon-stop:before { content: '\e801'; } /* '' */
.icon-pause:before { content: '\e802'; } /* '' */
.icon-to-end:before { content: '\e803'; } /* '' */
.icon-to-start:before { content: '\e804'; } /* '' */
.icon-ok:before { content: '\e805'; } /* '' */
.icon-cancel:before { content: '\e806'; } /* '' */
.icon-lock:before { content: '\e807'; } /* '' */
.icon-lock-open:before { content: '\e808'; } /* '' */
.icon-right-open:before { content: '\e809'; } /* '' */
.icon-left-open:before { content: '\e80a'; } /* '' */
.icon-up-open:before { content: '\e80b'; } /* '' */
.icon-down-open:before { content: '\e80c'; } /* '' */
.icon-award:before { content: '\e80d'; } /* '' */
.icon-thumbs-up:before { content: '\e80e'; } /* '' */
.icon-thumbs-down:before { content: '\e80f'; } /* '' */
.icon-star-1:before { content: '\e810'; } /* '' */
.icon-star-empty-1:before { content: '\e811'; } /* '' */
.icon-star:before { content: '\e812'; } /* '' */
.icon-star-empty:before { content: '\e813'; } /* '' */
.icon-star-half:before { content: '\e814'; } /* '' */
.icon-plus-circled:before { content: '\e815'; } /* '' */
.icon-plus:before { content: '\e816'; } /* '' */
.icon-plus-squared:before { content: '\e817'; } /* '' */
.icon-minus:before { content: '\e818'; } /* '' */
.icon-cancel-squared:before { content: '\e819'; } /* '' */
.icon-cancel-circled:before { content: '\e81a'; } /* '' */
.icon-comment:before { content: '\e81b'; } /* '' */
.icon-chat:before { content: '\e81c'; } /* '' */
.icon-th-list:before { content: '\e81d'; } /* '' */
.icon-search:before { content: '\e81e'; } /* '' */
.icon-pencil:before { content: '\e81f'; } /* '' */
.icon-hammer:before { content: '\e820'; } /* '' */
.icon-globe:before { content: '\e821'; } /* '' */
.icon-phone-circled:before { content: '\e822'; } /* '' */
.icon-phone:before { content: '\e823'; } /* '' */
.icon-at:before { content: '\e824'; } /* '' */
.icon-user:before { content: '\e825'; } /* '' */
.icon-chart-bar:before { content: '\e826'; } /* '' */
.icon-chart-bar-1:before { content: '\e827'; } /* '' */
.icon-attention:before { content: '\e828'; } /* '' */
.icon-attention-circled:before { content: '\e829'; } /* '' */
.icon-login:before { content: '\e82a'; } /* '' */
.icon-logout:before { content: '\e82b'; } /* '' */
.icon-forward:before { content: '\e82c'; } /* '' */
.icon-mail:before { content: '\e82d'; } /* '' */
.icon-location:before { content: '\e82e'; } /* '' */
.icon-target:before { content: '\e82f'; } /* '' */
.icon-users:before { content: '\e830'; } /* '' */
.icon-folder:before { content: '\e831'; } /* '' */
.icon-folder-open:before { content: '\e832'; } /* '' */
.icon-tags:before { content: '\e833'; } /* '' */
.icon-tag:before { content: '\e834'; } /* '' */
.icon-trash-empty:before { content: '\e835'; } /* '' */
.icon-trash:before { content: '\e836'; } /* '' */
.icon-download:before { content: '\e837'; } /* '' */
.icon-download-1:before { content: '\e838'; } /* '' */
.icon-spin6:before { content: '\e839'; } /* '' */
.icon-sun-filled:before { content: '\e83a'; } /* '' */
.icon-hail-inv:before { content: '\e83b'; } /* '' */
.icon-rain-inv:before { content: '\e83c'; } /* '' */
.icon-cloud-sun-inv:before { content: '\e83d'; } /* '' */
.icon-snow-heavy-inv:before { content: '\e83e'; } /* '' */
.icon-snow-inv:before { content: '\e83f'; } /* '' */
.icon-bus:before { content: '\e840'; } /* '' */
.icon-heart:before { content: '\e841'; } /* '' */
.icon-info:before { content: '\e842'; } /* '' */
.icon-pin:before { content: '\e843'; } /* '' */
.icon-crown:before { content: '\e844'; } /* '' */
.icon-pin-outline:before { content: '\e845'; } /* '' */
.icon-share:before { content: '\e846'; } /* '' */
.icon-list-add:before { content: '\e847'; } /* '' */
.icon-address:before { content: '\e848'; } /* '' */
.icon-info-circled:before { content: '\f085'; } /* '' */
.icon-info-circled-alt:before { content: '\f086'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-menu:before { content: '\f0c9'; } /* '' */
.icon-mail-alt:before { content: '\f0e0'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */
.icon-bell-alt:before { content: '\f0f3'; } /* '' */
.icon-quote-right:before { content: '\f10e'; } /* '' */
.icon-reply:before { content: '\f112'; } /* '' */
.icon-code:before { content: '\f121'; } /* '' */
.icon-star-half-alt:before { content: '\f123'; } /* '' */
.icon-help:before { content: '\f128'; } /* '' */
.icon-attention-alt:before { content: '\f12a'; } /* '' */
.icon-calendar-empty:before { content: '\f133'; } /* '' */
.icon-pencil-squared:before { content: '\f14b'; } /* '' */
.icon-doc-text-inv:before { content: '\f15c'; } /* '' */
.icon-thumbs-up-alt:before { content: '\f164'; } /* '' */
.icon-thumbs-down-alt:before { content: '\f165'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-graduation-cap:before { content: '\f19d'; } /* '' */
.icon-google:before { content: '\f1a0'; } /* '' */
.icon-cube:before { content: '\f1b2'; } /* '' */
.icon-chart-area:before { content: '\f1fe'; } /* '' */
.icon-chart-pie:before { content: '\f200'; } /* '' */
.icon-chart-line:before { content: '\f201'; } /* '' */
.icon-whatsapp:before { content: '\f232'; } /* '' */
.icon-user-times:before { content: '\f235'; } /* '' */
.icon-hashtag:before { content: '\f292'; } /* '' */