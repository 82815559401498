.schoolDetailsVoteCloudAuthStage {
  flex: 1;
  display: flex;
  flex-direction: column;

  .body {
    flex: 1;
    display: flex;
    margin-bottom: 8px;

    .vote-icon {
      display: flex;
    }

    .form {
      width: 0;
      flex: 1;

      .info {
        margin-bottom: 7px;
      }
      .waiting-bar {
        display: flex;
        margin-right: 5px;
        width: 22px;
        height: 22px;
      }
    }

    .input-area {
      text-align: left;

      .other-method {
        text-align: center;
        span {
          font-size: 12px;
        }

        &:hover {
          transition: 0.2s ease-out;
          cursor: pointer;
          color: #aaa;
        }
      }
    }

    .space-wrapper {
      position: relative;
      display: flex;
      justify-content: center;

      .line-wrapper {
        flex: 1;

        .line {
        }
      }

      .or {
        position: absolute;
        background: #28292a;
        top: 0;
        padding: 0 6px;
        font-size: 11px;
        font-weight: bold;
      }
    }
  }

  .footer {
    flex: 1;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #686868;
    padding: 3px;
    padding-top: 10px;
  }

  .row {
    &.facebook-area,
    &.email-area {
      & > span {
        display: flex;
        width: 100%;
      }
    }
  }
}
