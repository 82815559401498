@import "../../../assets/style/styles";

.Podium {
  flex: 1;
  display: flex;

  .podium {
    flex: 1;
    display: flex;
    flex-direction: column;

    .places {
      width: 100%;
      flex: 1;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      .place {
        position: relative;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        flex: 1;
        margin: 0 5px;

        &:first-child {
          .position-box {
            height: 55px;
            background-color: #51679b;
          }
        }

        &:last-child {
          .position-box {
            height: 40px;
            background-color: #43547c;
          }
        }

        .avatar-box {
          @extend .middle;
          width: 100%;
          flex: 1;
          margin-bottom: 10px;

          .avatar {
            width: 50px;
            height: 50px;

            h2 {
              font-size: 12px !important;
            }
          }
        }

        .position-box {
          @extend .middle;
          width: 100%;
          height: 90px;
          background-color: #6885cc;
          border-radius: 10px 10px 0 0;

          span {
            color: white;
            font-family: Roboto;
            font-weight: bold;
            font-size: 35px;
            text-shadow: 0 0 10px #101f43;
          }
        }
      }
    }
  }
}
