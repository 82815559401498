.iFramePreviewWindow {
  .window-inner {
    max-width: 620px;
  }
  .content {
    margin-top: 10px;
    max-height: 400px;
    overflow-y: scroll;

    .preview-and-options {
      display: flex;
    }

    .code-wrapper {
      text-align: left;
      display: flex;
      justify-content: flex-start;
      font-size: 15px;

      code {
        padding: 15px;
        background: #1b1b1b;
        box-sizing: border-box;
        border-radius: 5px;
      }
    }

    .options {
      padding: 0 20px;
      flex: 1;

      .MuiFormControl-root {
        text-align: left;

        .MuiFormControlLabel-root {
          margin-left: 0;
        }

        .PrivateSwitchBase-root-1 {
          padding: 5px;
          padding-left: 0;
        }
      }

      .MuiRadio-colorSecondary.Mui-checked {
        color: #815df9;
      }
    }
  }
}
