.Loader {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin-right: 5px;

  .shadow {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 8px solid #353b49;
    animation: higlight-loader-shadow 1s ease-out infinite;
  }

  .loader {
    border: 8px solid #454545;
    border-radius: 50%;
    border-top: 8px solid #5b88f8;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    animation: spin-loader 0.5s linear infinite;
  }

  @keyframes higlight-loader-shadow {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(1.8);
      opacity: 0;
    }
  }

  @keyframes spin-loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
