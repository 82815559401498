@import "../../../assets/style/styles";

.Logo {
  cursor: pointer;
  display: flex;
  position: relative;
  justify-content: center;

  .version {
    position: absolute;
    right: 0;
    font-size: 7px;
    font-weight: bold;
    background: #618eff;
    border-radius: 5px;
    padding: 2px;
    color: #ddd;
  }

  h1 {
    margin: 0;
    font-family: "Secular One", sans-serif;
    line-height: 15px;
    font-weight: normal;
    text-transform: uppercase;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 5px;
    text-decoration: none;

    .first {
      font-size: 16px;
      color: #d6d5f5;
      font-weight: normal;
      letter-spacing: 0.5px;
      transform: translateY(100%);
      opacity: 0;
      animation: animate-first-part-logo 0.4s 1;
      animation-fill-mode: forwards;
      animation-delay: 1.4s;
      text-decoration: none;
    }

    .second {
      background: linear-gradient(45deg, #618eff, #c642a4);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      clip-path: inset(0% 100% 0% 0%);
      font-size: 15px;
      text-decoration: none;

      &.animating {
        animation: animate-second-part-logo 0.7s 1;
        animation-fill-mode: forwards;

        animation-delay: 1.8s;
      }

      .second-end {
        font-size: 8px;
      }
    }
  }

  .info {
    font-size: 12px;
    text-align: right;
    position: absolute;
    bottom: 0;
  }

  @keyframes animate-first-part-logo {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    40% {
      transform: translateY(-20%);
    }
    60% {
      transform: translateY(10%);
    }
    80% {
      transform: translateY(-5%);
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes animate-second-part-logo {
    0% {
      clip-path: inset(0% 100% 0% 0%);
    }
    100% {
      clip-path: inset(0% 0% 0% 0%);
    }
  }
}
