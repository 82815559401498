$monitor-height: 85%;
$stand-height: 100% - $monitor-height;

.MonitorIcon {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  .screen {
    width: 100%;
    height: $monitor-height;
    border: 2px solid #6b86f5;
    background: linear-gradient(45deg, #3f44a6, #ab2488);
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;

    span {
      color: #d6d5f5;
      font-size: 10px;
      font-family: "Courier";
      font-weight: bold;
    }
  }

  .stand {
    width: 18%;
    height: $stand-height;
    border: 2px solid #6b86f5;
    border-top: none;
    border-bottom: none;
    box-sizing: border-box;
  }

  .underlay {
    width: 45%;
    height: 0%;
    border-top: 2px solid #6b86f5;
    border-radius: 10px;
    box-sizing: border-box;
  }
}
