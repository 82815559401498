@import "../../../assets/style/styles";

$image_url: "/assets/images/welcome-icon.png";

.bubble.Welcome {
  flex: 2;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  .motion-bubble {
    overflow: hidden;

    .motion-bubble-inner {
      flex-direction: column;
    }
  }

  .gradient-background-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    overflow: hidden;
    justify-content: center;
    align-items: center;

    .gradient-background-box {
      position: absolute;
      top: 250px;
      left: -250px;
      width: 900px;
      height: 700px;
      animation: transform-gradient-box 5s 1;
      animation-fill-mode: forwards;

      .gradient-background {
        width: 100%;
        height: 100%;
        background: linear-gradient(334deg, #8d4398, #3a69d9);
        animation: transform-gradient-bubble 7.5s infinite;
        border-radius: 40%;
      }
    }

    @keyframes transform-gradient-box {
      0% {
        top: 250px;
      }

      100% {
        top: -20px;
      }
    }

    @keyframes transform-gradient-bubble {
      0% {
        transform: rotate(0);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }

  .more {
    display: flex;
  }

  h1 {
    @extend .hideable;

    width: 100%;

    color: white;
  }
  p {
    flex: 3;
    text-align: left;
    line-height: 20px;
    font-family: "Nunito";
    color: #e2e2e2;
    font-weight: bold;
  }

  .icon {
    flex: 1;
    @extend .image;
    background-size: contain;
    z-index: 1;
  }
}
