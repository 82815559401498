@import "./assets/style/styles";
@import "./assets/style/responsive";

html {
  height: 100%;
}

.grecaptcha-badge {
  display: none;
}

.App {
  text-align: center;
  display: flex;
  background-color: #171819;
  z-index: 10000;
  width: 100%;
  height: 100%;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  h1,
  h2,
  h3,
  h4,
  h5 {
    text-align: left;
    margin: 0;
    color: #c5c5c5;
    font-family: "Nunito", sans-serif;

    font-weight: bold;
  }

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 16px;
  }

  hr {
    border-color: #6b6b6b;
  }

  a {
    color: none;
    text-decoration: none;
    &.app-link {
      color: #7a9ef6;
      text-decoration: underline;
      transition: 0.15s ease-out;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }
  }
  h1,
  h2,
  h3 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;

    .app-header-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      background: #729bff;
      margin-right: 8px;
      color: white;
      font-size: 15px;
      margin-bottom: 5px;
    }
  }

  .app-button {
    background-color: #496eca;
    color: white;
    padding: 0 20px;

    &:disabled {
      background-color: #5c6d97;
      color: #ddd;
    }

    &:hover {
      background-color: #3f69d1;
    }
  }

  .auth-button {
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    flex: 1;
    height: 35px;
    border-radius: 4px;
    color: white;
    border: 0px transparent;
    text-align: center;
    margin: 5px;
    display: inline-block;
    font-family: "Nunito", sans-serif;
    font-weight: bold;
    & > i {
      margin-right: 5px;
    }

    &.facebook-button {
      background: #3b5998;

      &:hover {
        background: #3b5998;
      }
    }

    &.email-button {
      background: #fc5b62;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  .MuiTextField-root {
    .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
      &.Mui-focused {
        &::after {
          border-color: rgb(120, 159, 255);
        }
      }
    }
  }

  .MuiFormControl-root,
  .MuiSelect {
    width: 100%;
    flex: 1;
    margin: 8px 0;

    .MuiOutlinedInput-input {
      padding: 7px 10px;
    }

    .Mui-focused {
      &.MuiFormLabel-root {
        color: #7a9ef6;
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: #7a9ef6;
      }
    }

    .MuiOutlinedInput-input {
      font-size: 14px;
      color: #cccccc;
    }

    .MuiInputLabel-outlined {
      transform: translate(14px, 8px) scale(1);

      &.MuiInputLabel-shrink {
        transform: translate(14px, -6px) scale(1);
        font-size: 11px;
      }
    }
  }
  .MuiFormHelperText-root {
    @extend .hideable;

    &.Mui-disabled {
    }
  }

  .MuiSelect {
    text-align: left;

    &.Mui-error {
      .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
        color: #f44336;
      }
    }

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: #7a9ef6;
      }

      .PrivateNotchedOutline-legendLabelled-3 {
        visibility: visible;
        color: #7a9ef6;
      }
    }
  }

  .icon-spin6 {
    animation: 1s spinner infinite;
    animation-timing-function: linear;
  }

  .error {
    color: #f44336;
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
