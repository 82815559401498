@import "../../../assets/style/styles";

.SchoolDetailsContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 0%;
  overflow: hidden;

  .section-options {
    display: flex;
    a {
      @extend .hideable;
      flex: 1;
      display: flex;
    }
    .button {
      font-size: 12.5px;
      flex: 1;
      i {
        margin-right: 2px;
      }
    }
  }

  & > .content {
    flex: 1;
    height: calc(100% - 32px);
    max-width: 380px;

    .Frame {
      height: 100%;

      .content-wrapper {
        height: 100%;
      }
    }
  }
}
