.fetch-spinner {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: linear-gradient(45deg, #618eff, #c642a4);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: animate-fetch-spinner 0.6s infinite;

  .fetch-spinner-shell {
    width: 65%;
    height: 65%;
    background: #171819;
    border-radius: 50%;
  }
}

@keyframes animate-fetch-spinner {
  0% {
    transform: rotate(0);
  }
  70% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(260deg);
  }
}
