.Select {
  width: 100%;
  flex: 1 1;
  margin: 8px 0;

  &.select-error {
    .helperText,
    select {
      border-color: #f44336;
      color: #f44336;

      &:focus {
        color: #cccccc;
      }
    }
  }

  select {
    width: 100%;
    border-color: rgba(255, 255, 255, 0.23);
    background: transparent;
    font-size: 14px;
    color: #cccccc;
    padding: 7px 10px;
    border-radius: 3px;
    transition: 0.05s ease-out;
    font-family: "Roboto";

    &:focus {
      outline: none;
      border-color: #7a9ef6;
      box-shadow: 0 0 1px 1px #7a9ef6;
    }

    option {
      background: inherit;
      background: #222;
    }
  }

  .helperText {
    color: rgba(255, 255, 255, 0.7);
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    margin-left: 14px;
    margin-right: 14px;
  }
}
