@import "../../../assets/style/styles";

.SchoolDetailsVoteCloudFormStage {
  flex: 1;
  display: flex;
  flex-direction: column;

  .body {
    flex: 1;
    display: flex;

    .vote-icon {
      display: flex;
    }

    .form {
      width: 0;
      flex: 1;

      .row {
        position: relative;
        display: flex;

        .icon-wrapper {
          @extend .middle;
          width: 20px;
          height: 20px;
          box-sizing: border-box;
          position: absolute;
          margin-top: 6px;
          top: 6px;
          right: 6px;
          padding: 2px;
          background: #486dc8;
          border-radius: 5px;

          .icon-facebook {
            color: #eee;
            font-size: 10px;
            transform: translate(-1px, 0);
          }

          .icon-ok {
            @extend .middle;
            position: absolute;
            font-size: 6px;
            color: #eee;
            transform: translate(7px, 7px);
            background: #3aa63a;
            border: 1px solid #28292a;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            padding-top: 1px;
            padding-left: 1px;
            box-sizing: border-box;
          }
        }
      }
    }
  }

  .footer {
    flex: 1;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #686868;
    padding: 3px;
    padding-top: 10px;
  }

  .facebook-area {
    display: flex;
    align-items: center;
  }
}
