.SchoolDetailsComments {
  position: relative;
  flex: 1;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .Oops {
    .oops-header {
      color: #898989;
    }
  }
}
