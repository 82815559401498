html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #171819;
  color: #73757f;
}

#root {
  width: 100%;
  height: 100%;
}

#root > div {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 7px;
  border-radius: 7px;
  background-color: transparent;
  color: #aba499;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #454a4d;
}
::selection {
  background-color: #868686 !important;
  color: #e8e6e3 !important;
}
