.app-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .progress-wrapper {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .Logo {
      transform: scale(1.5);
    }
    .bar {
      max-width: 90%;
      margin: 20px 0;
      width: 280px;
      height: 6px;
      background: #333;
      border-radius: 15px;
      overflow: hidden;
      .inside {
        height: 100%;
        max-width: 100%;
        animation: load-app 3s 1;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0, 0, 0.81, 0.27);
        background-color: #618eff;
        background: linear-gradient(45deg, #618eff, #c642a4);
      }
    }

    .bottom {
      position: fixed;
      z-index: -1;
      bottom: -15px;
      width: 100%;
      height: 33vh;
      display: flex;
      justify-content: center;
      padding: 0 5vw;
      max-width: 1200px;
      box-sizing: border-box;

      .ball-wrapper {
        flex: 1;
        margin: 0 5px;
      }

      .ball {
        position: absolute;
        width: 15px;
        height: 15px;
        background: linear-gradient(45deg, #618eff, #c642a4);
        border-radius: 50%;
        bottom: 0;

        animation: 4s animate-ball infinite;

        .ball-border {
          width: 100%;
          height: 100%;
          border: 3px solid #c642a4;
          border-radius: 50%;
          box-sizing: border-box;
          position: absolute;
          top: 0;
          left: 0;
          animation: 0.5s animate-ball-border infinite;
        }

        .ball-shell {
          width: 100%;
          height: 100%;
          animation: 0.5s animate-ball-shell infinite;
          border-radius: 50%;
          background: linear-gradient(45deg, #618eff, #c642a4);
        }

        &._1 {
          &,
          .ball-border {
            animation-duration: 2s;
          }
        }
        &._2 {
          &,
          .ball-border {
            animation-duration: 3s;
          }
        }
        &._3 {
          &,
          .ball-border {
            animation-duration: 2.5s;
          }
        }
        &._4 {
          &,
          .ball-border {
            animation-duration: 1.5s;
          }
        }
        &._5 {
          &,
          .ball-border {
            animation-duration: 3.5s;
          }
        }
        &._6 {
          &,
          .ball-border {
            animation-duration: 2.7s;
          }
        }
        &._7 {
          &,
          .ball-border {
            animation-duration: 1.65s;
          }
        }
        &._8 {
          &,
          .ball-border {
            animation-duration: 1.5s;
          }
        }
        &._9 {
          &,
          .ball-border {
            animation-duration: 2.9s;
          }
        }
        &._10 {
          &,
          .ball-border {
            animation-duration: 2s;
          }
        }
      }

      @keyframes animate-ball {
        0% {
          bottom: 0;
          opacity: 1;
        }
        100% {
          bottom: 100%;
          opacity: 0;
        }
      }

      @keyframes animate-ball-shell {
        0% {
          transform: rotate(0);
        }
        100% {
          transform: rotate(360deg);
        }
      }

      @keyframes animate-ball-border {
        0% {
          transform: scale(1);
        }
        60% {
          transform: scale(1);
        }
        98% {
          opacity: 1;
        }
        100% {
          transform: scale(5);
          opacity: 0;
        }
      }
    }
  }
}
@keyframes load-app {
  0% {
    width: 1%;
  }

  100% {
    width: 100%;
  }
}
