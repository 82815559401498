@import "../../../assets/style/styles";

.schoolAvatar {
  @extend .middle;
  width: 80px;
  height: 80px;
  position: relative;

  .inner {
    @extend .image;
    @extend .middle;

    background-color: #3e3e3e;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    box-shadow: 0 0 3px 1px #111;
    position: relative;
    transition: background-color 0.3s ease-out;

    .initials {
      color: white;
      font-family: Roboto;
      text-transform: uppercase;
    }

    .place {
      @extend .middle;
      position: absolute;
      bottom: -8px;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      box-shadow: 0 0 3px 1px #111;
      overflow: hidden;
      transition: background-color 0.3s ease-out;

      & > div {
        @extend .middle;
        width: 100%;
        height: 100%;
        background-color: rgba(90, 90, 90, 0.4);

        span {
          color: white;
          font-size: 11px;
          font-weight: bold;
          font-family: Roboto;
        }
      }
    }
  }
}
