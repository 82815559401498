@import "../../../assets/style/styles";

.menu {
  flex-shrink: 0;
  display: flex;
  width: 350px;
  height: 100vh;
  position: relative;

  & > .menu-fixed {
    flex: 1;
    background: $menu-background;
    display: flex;
    align-items: center;
    position: fixed;
    overflow: hidden;
    z-index: 8000;
    width: 350px;
    height: 100vh;

    .menu-middle {
      width: 100%;
      height: 100%;
      max-height: 1200px;
      position: relative;
      display: flex;
      flex-direction: column;

      & > div {
        width: 100%;
        flex-shrink: 1;
      }

      .logo {
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;

        .Logo {
          transform: scale(1.5);
        }
      }

      .options {
      }

      .mini-footer {
        position: absolute;
        bottom: 0;
      }
    }
  }
}
