.LogoIcon {
  animation: scale-logo-icon 0.4s 1;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  opacity: 0;

  .MonitorIcon {
    .screen {
      border: none;
      span {
        font-family: Roboto;
        font-size: 14px;
      }
    }

    .stand {
      border-color: #636b8f;
    }

    .underlay {
      border-color: #4c56aa;
    }
  }

  @keyframes scale-logo-icon {
    0% {
      transform: scale(0);
      opacity: 0;
    }

    40% {
      transform: scale(1.15);
    }

    60% {
      transform: scale(0.85);
    }

    80% {
      transform: scale(1.08);
      opacity: 1;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
}
