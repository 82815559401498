@import "../../../assets/style/styles";

$place-flex: none;
$score-flex: none;
$name-flex: 4;
$city-flex: 2;
$stars-flex: 2;
$votes-up-flex: 1;
$votes-down-flex: 1;

.RankBox {
  .rankBox {
    flex: 1;

    .ranking {
      .ranking-header {
        display: flex;
        justify-content: space-between;

        .disable-filter-button {
          font-size: 12px;
          padding: 0 15px;
        }
      }
    }

    .school-previews-header {
      display: flex;
      border-bottom: 1px solid #343434;
      padding: 5px 0;

      span,
      i {
        font-size: 11px;
        font-weight: bold;
      }

      .place {
        width: 22px;
        margin-right: 5px;
        flex: $place-flex;
      }

      .score {
        width: 42px;
        flex: $score-flex;
        padding: 0 8px;
        box-sizing: border-box;
      }

      .name {
        flex: $name-flex $name-flex;
        padding: 0 10px;
      }

      .city {
        flex: $city-flex $city-flex;
      }

      .stars {
        flex: $stars-flex $stars-flex;
      }

      .votes-up {
        flex: $votes-up-flex $votes-up-flex;
        padding: 0 3px;
      }

      .votes-down {
        flex: $votes-down-flex $votes-down-flex;
        padding: 0 3px;
      }
    }
  }

  .rank-box-oops {
    margin-top: 40px;
  }
}
