@import "../../../assets/style/styles";

.SchoolDetailsTop {
  .title {
    max-width: 377px;
    @extend .hideable;
    text-align: center !important;
    display: block;
    text-transform: uppercase;
    font-size: 13px;
  }

  .grade {
  }
}
