.addSchoolForm {
  flex: 3;

  .required-form,
  .additional-form,
  .submit-form {
    .content {
      width: 100%;
    }
    .input-wrapper {
      width: 100%;
    }
  }

  .in-verification-messsage {
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    color: #e6c269;
  }
}
