@import "../../../assets/style/styles";

.menuOption {
  .option-button {
    width: 100%;
    height: 65px;
    background: transparent;
    border: none;

    font-family: "Nunito", sans-serif;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: 0.3s ease-out;
    padding: 0 10px;
    box-sizing: border-box;
    color: #a0a4e9;

    &:hover {
      color: #7a9ef6;
    }

    &.selected {
      border-right: 4px solid #7f85eb;
      color: #7f85eb;
      background: linear-gradient(45deg, transparent, rgba(106, 83, 179, 0.3));
      font-weight: bold;
    }

    .icon {
      @extend .middle;
      width: 60px;
      height: 60px;

      i {
        font-size: 25px;
      }
    }

    .title {
      flex: 1;
      text-align: left;
      padding: 0 5px;
      overflow: hidden;
      position: relative;
      white-space: nowrap;
      text-overflow: ellipsis;

      span {
        font-size: 14px;
        letter-spacing: 1px;
      }
    }
  }
}
