.pages {
  height: 100%;
  flex-grow: 1;
  padding: 1.5rem;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  display: flex;
  justify-content: space-around;
  position: relative;

  & > .windows {
    position: absolute;
    top: 0;
    left: 0;
  }

  & > .content {
    flex: 1;
    max-width: 1200px;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
}
