.ShareWindow {
  .share-window-inner {
    width: 100%;
  }
  .share-options {
    display: flex;
    margin: 10px 0;
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;

    .option {
      width: 60px;
      margin-right: 15px;
      opacity: 0.9;

      &:hover {
        cursor: pointer;
        opacity: 1;

        & > .icon {
          & > i {
            transform: scale(1.2);
          }
        }
      }

      .icon {
        width: 100%;
        height: 60px;
        border-radius: 50%;
        background: #3d3d41;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        i {
          color: white;
          font-size: 30px;
          transition: 0.2s ease-out;
        }
      }

      .text {
        width: 100%;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        span {
          color: #ddd;
          font-size: 12px;
          font-family: Roboto;
        }
      }
    }
  }
  .copy-url {
    display: flex;
    background: #181818;
    padding: 5px 15px;
    border: 1px solid #ffffff1a;

    .copy-url-input {
      color: #ddd;
      background: inherit;
      border: none;
      flex: 1;
      padding: 0;
      &:focus {
        outline: none;
      }
    }

    button {
      color: #7f85eb;
    }
  }
}
