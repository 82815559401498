/* responsive */
@media screen and (max-width: 1520px) {
  .App {
    .menu {
      width: 280px;

      & > .menu-fixed {
        width: 280px;
        .menu-middle {
          .logo {
            height: 120px;
            .Logo {
              transform: scale(1.2);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1420px) {
  .App {
    .menu {
      width: 230px;
      & > .menu-fixed {
        width: 230px;
        .menu-middle {
          .logo {
            height: 100px;
            .Logo {
              transform: scale(1);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .App {
    .menu {
      & > .menu-fixed {
        .menu-middle {
          .logo {
            height: 90px;
            .Logo {
              transform: scale(0.9);
            }
          }

          .menuOption .option-button .title span {
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1320px) {
  .App {
    .menu {
      width: 80px;

      .menu-fixed {
        width: 80px;
      }

      .menuOption {
        .title {
          display: none;
        }
      }

      .Logo {
        h1 {
          display: none;
        }
      }

      .Footer {
        .bar {
          width: auto;
          .photo {
            margin-right: 0;
          }
          .content {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .App {
    .rankBox {
      .schoolPreview,
      .school-previews-header {
        .city {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 1196px) {
  .App {
    .bubble {
      &.Welcome,
      &.WelcomeAddSchool,
      &.welcome {
        p {
          font-size: 15px;
          line-height: 18px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1153px) {
  .App {
    .SchoolDetails-wrapper {
      .SchoolDetails {
        & > .motion-bubble {
          .inside {
            width: auto !important;

            .middle-side {
              opacity: 1 !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1152px) and (min-width: 651px) {
  .App {
    .SchoolDetails-wrapper {
      .SchoolDetails {
        .motion-bubble {
          position: absolute;
          right: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 1152px) {
  .App {
    .SchoolDetails-wrapper {
      width: 75px;
      .SchoolDetails {
        width: 75px;

        .left-side {
          .hide-icon {
            display: flex !important;
          }
        }

        .motion-bubble {
          box-shadow: -30px 0px 30px 1px #191919;
        }
      }
    }

    .rankBox {
      .schoolPreview,
      .school-previews-header {
        .city {
          display: flex;
        }
      }
    }
  }
}

@media screen and (max-width: 1070px) {
  .App {
    .pages {
      padding: 0;
      align-items: flex-start;
    }

    .Podium {
      .podium {
        .places {
          .place {
            flex: 0;
          }
        }
      }
    }

    .Ranking {
      .top {
        flex-direction: column;
      }
    }
  }
}

@media screen and (max-width: 970px) {
  .App {
    .addSchool {
      .top {
        flex-direction: column;
      }
    }

    .Experts {
      .main {
        flex-direction: column-reverse;
        & > div {
          .info {
            .content {
              .experts {
                max-height: none;
              }
            }
          }
        }
      }
    }

    .Contact {
      .main {
        flex-direction: column;
      }
    }

    .Rules {
      .main {
        flex-direction: column-reverse;
      }
    }
  }
}

@media only screen and (max-width: 812px) and (orientation: portrait) {
  .App {
  }
}

@media only screen and (max-width: 790px) {
  .App {
    .rankBox {
      .schoolPreview,
      .school-previews-header {
        .city {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 675px) {
  .App {
    .rankBox {
      .schoolPreview,
      .school-previews-header {
        .name {
          span {
            font-size: 11px;
          }
        }
        .stars {
          i {
            font-size: 9px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 650px) and (orientation: portrait) {
  .App {
    .Top {
      display: flex;

      .Logo {
        h1 {
        }
      }
    }
    .bubble {
      .motion-bubble {
        padding: 20px;
      }
    }
    .menu {
      width: 0;
      .menu-fixed {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 65px;

        .menu-middle {
          height: auto;
          .options {
            display: flex;

            .menuOption {
              flex: 1;

              .option-button {
                display: flex;
                justify-content: center;
                border-top: 4px solid #3c3c3c;

                .icon {
                  width: auto;
                  height: auto;
                }
                &.selected {
                  border-top: 4px solid #7f85eb;
                  background: linear-gradient(
                    0deg,
                    transparent,
                    rgba(73, 90, 132, 0.3)
                  );
                  border-right: none;
                }
              }
            }
          }

          .logo {
            display: none;
          }

          .mini-footer {
            display: none;
          }
        }
      }
    }

    .pages {
      & > .content {
        margin-top: 50px;
        margin-bottom: 130px;
        padding: 0;
      }

      .SchoolDetails-wrapper {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        .SchoolDetails {
          position: absolute;
          bottom: 0;
          margin: 0;
          & > .motion-bubble {
            position: fixed;
            z-index: 1;
            bottom: 65px;
            left: 0;
            width: 100vw;
            box-sizing: border-box;

            height: auto;
            border-radius: 30px 30px 0 0;
            box-shadow: 0 0 130px 10px #181818;
            justify-content: center;
            overflow: visible;

            .motion-bubble-inner {
              width: 90vw;
            }

            .inside {
              width: 100%;
              height: 0;
              .top-side {
                .hide-icon {
                  display: flex !important;
                }
              }

              .left-side {
                .hide-icon {
                  display: none !important;
                }
              }

              .schoolBackground {
                .controlls {
                  display: none;
                }
              }

              .middle-side {
                .schoolDetails {
                  width: 100%;
                  flex: 1;

                  .SchoolDetailsContent {
                    h3.title {
                      max-width: 100%;
                    }

                    & > .content {
                      max-width: initial;
                    }
                    .content-wrapper {
                    }

                    .SchoolDetailsUserComments,
                    .SchoolDetailsGoogleReviews {
                      width: 100%;
                    }
                    .schoolDetailsExpertOpinion,
                    .SchoolDetailsGoogleReview,
                    .schoolDetailsUserComment {
                      width: auto;
                    }
                  }
                  .SchoolDetailsTop {
                    .title {
                      max-width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 650px) and (max-height: 650px) and (orientation: portrait) {
  .App {
    h3 {
      font-size: 15px;
    }
    .schoolBackground {
      height: 65px !important;
    }
    .schoolAvatar {
      width: 65px;
      height: 65px;

      .inner {
        width: 65px;
        height: 65px;
      }
    }
    .MuiFormControl-root,
    .MuiSelect {
      margin: 5px 0 !important;
    }

    .Cloud {
      position: fixed;
      bottom: 75px;
      z-index: 1000;
      width: 90%;
      max-height: calc(100vh - 140px);
    }
  }
}

@media only screen and (max-width: 650px) and (orientation: landscape) {
  .App {
    .SchoolDetailsContent {
      height: 87%;
    }
  }
}

@media only screen and (max-width: 520px) and (orientation: portrait) {
  .App {
    .rank-search-filter {
      display: none;
    }
    .RankSearch .rankSearch .input-wrapper {
      border-right: none;
    }

    .schoolPreview,
    .school-previews-header {
      .stars {
        display: none;
      }
    }

    .bubble {
      &.Welcome,
      &.WelcomeAddSchool,
      &.welcome {
        h1 {
          font-size: 24px;
        }
        p {
          font-size: 13.5px;
          line-height: 16px;
        }
        .content {
          .icon {
            height: 130px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 490px) and (orientation: portrait) {
  .App {
    .school-previews {
      width: 90%;
    }
    .schoolPreview-wrapper {
      .schoolPreview,
      .school-previews-header {
        .name {
          flex: 7 7;
        }
        .votes-up,
        .votes-down {
          span {
            font-size: 10px;
          }
        }
        .place {
          width: 18px;
          height: 18px;
          span {
            font-size: 9px;
          }
        }
        .score {
          width: 32px;
          padding: 0 2px;
          span {
            font-size: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 460px) and (orientation: portrait) {
  .App {
    .bubble {
      &.Welcome,
      &.WelcomeAddSchool,
      &.welcome {
        h1 {
          font-size: 20px;
        }
        p {
          font-size: 12.5px;
          line-height: 15px;
        }
        .content {
          .icon {
            height: 110px;
          }
        }
      }
    }

    .Cloud {
      .vote-icon {
        .icons {
          .vote-type-icon {
            font-size: 40px;
          }
        }
      }
      .content {
        .info {
          font-size: 14px;
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) and (orientation: portrait) {
  .App {
    .bubble {
      &.Welcome,
      &.WelcomeAddSchool,
      &.welcome {
        h1 {
          font-size: 17px;
        }
        p {
          font-size: 12.5px;
          line-height: 14px;
        }
        .content {
          .icon {
            height: 100px;
          }
        }
      }
    }
  }
}

/**/

@media only screen and (max-height: 550px) and (orientation: landscape) {
  .App {
    .menuOption {
      .option-button {
        height: 55px;

        .icon {
          i {
            font-size: 22px;
          }
        }
      }
    }
  }
}

@media only screen and (max-height: 455px) and (orientation: landscape) {
  .App {
    .Footer {
      display: none;
    }
    .options {
      flex: 1;
      display: flex;
      flex-direction: column;
      .menuOption {
        flex: 1;
        .option-button {
          height: auto;

          .icon {
            i {
              font-size: 25px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-height: 420px) and (orientation: landscape) {
  .App {
    .options {
      .menuOption {
        .option-button {
          .icon {
            i {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
