.FacebookDeletionStatus {
  display: flex;
  justify-content: center;
  & > div {
    max-width: 480px;
  }

  .icon-wrapper {
    display: flex;
    justify-content: center;
  }

  .check-fb-status {
    padding: 5px 10px;
    margin: 10px;
  }

  .success-bar,
  .error-bar {
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 14px;
    .icon-ok,
    .icon-cancel {
      width: 60px;
      height: 60px;

      border-radius: 10px;
      color: #333;
      font-size: 30px;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .icon-ok {
      background: #50ff68;
    }

    .icon-cancel {
      background: #fe6666;
    }
  }
}
