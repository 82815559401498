.schoolDetailsVoteCloudErrorStage {
  flex: 1;
  display: flex;
  flex-direction: column;

  .body {
    flex: 1;
    display: flex;

    .vote-icon {
      display: flex;
    }

    .form {
      width: 0;
      flex: 1;
      .waiting-bar {
        display: flex;
        margin-right: 5px;
        width: 22px;
        height: 22px;
      }
    }
  }
}
