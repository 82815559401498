@import "../../../assets/style/styles";

.Window {
  @extend .middle;
  position: fixed;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.45);
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;

  .window-inner {
    max-width: 520px;
    display: flex;

    .bubble {
      flex: 1;
      position: relative;

      .close {
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 1000;

        i {
          font-size: 22px;
          transition: 0.3s ease-out;

          &:hover {
            color: #bbb;
            cursor: pointer;
          }
        }
      }
    }
  }
}
