.Background {
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  .background-wrap {
    bottom: 0;
    left: 0;
    position: relative;
    width: 50%;
    height: 50vh;
    z-index: -1;
    opacity: 0.7;
  }

  /* KEYFRAMES */

  @-webkit-keyframes animateBubble {
    0% {
      margin-top: 500px;
    }
    100% {
      margin-top: -8%;
      opacity: 0;
    }
  }

  @-moz-keyframes animateBubble {
    0% {
      margin-top: 500px;
    }
    100% {
      margin-top: -8%;
      opacity: 0;
    }
  }

  @keyframes animateBubble {
    0% {
      margin-top: 500px;
    }
    100% {
      margin-top: -8%;
      opacity: 0;
    }
  }

  @-webkit-keyframes sideWays {
    0% {
      margin-left: 0px;
    }
    100% {
      margin-left: 50px;
    }
  }

  @-moz-keyframes sideWays {
    0% {
      margin-left: 0px;
    }
    100% {
      margin-left: 50px;
    }
  }

  @keyframes sideWays {
    0% {
      margin-left: 0px;
    }
    100% {
      margin-left: 50px;
    }
  }

  /* ANIMATIONS */

  .x1 {
    -webkit-animation: animateBubble 10s linear infinite,
      sideWays 2s ease-in-out infinite alternate;
    -moz-animation: animateBubble 10s linear infinite,
      sideWays 2s ease-in-out infinite alternate;
    animation: animateBubble 10s linear infinite,
      sideWays 2s ease-in-out infinite alternate;

    left: -5%;
    top: 5%;

    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    transform: scale(0.6);
  }

  .x2 {
    -webkit-animation: animateBubble 3s linear infinite,
      sideWays 4s ease-in-out infinite alternate;
    -moz-animation: animateBubble 3s linear infinite,
      sideWays 4s ease-in-out infinite alternate;
    animation: animateBubble 3s linear infinite,
      sideWays 4s ease-in-out infinite alternate;

    left: 5%;
    top: 80%;

    -webkit-transform: scale(0.4);
    -moz-transform: scale(0.4);
    transform: scale(0.4);
  }

  .x3 {
    -webkit-animation: animateBubble 2.5s linear infinite,
      sideWays 2s ease-in-out infinite alternate;
    -moz-animation: animateBubble 2.5s linear infinite,
      sideWays 2s ease-in-out infinite alternate;
    animation: animateBubble 2.5s linear infinite,
      sideWays 2s ease-in-out infinite alternate;

    left: 10%;
    top: 40%;

    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    transform: scale(0.7);
  }

  .x4 {
    -webkit-animation: animateBubble 4s linear infinite,
      sideWays 3s ease-in-out infinite alternate;
    -moz-animation: animateBubble 4s linear infinite,
      sideWays 3s ease-in-out infinite alternate;
    animation: animateBubble 4s linear infinite,
      sideWays 3s ease-in-out infinite alternate;

    left: 20%;
    top: 0;

    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    transform: scale(0.3);
  }

  .x5 {
    -webkit-animation: animateBubble 6s linear infinite,
      sideWays 4s ease-in-out infinite alternate;
    -moz-animation: animateBubble 6s linear infinite,
      sideWays 4s ease-in-out infinite alternate;
    animation: animateBubble 6s linear infinite,
      sideWays 4s ease-in-out infinite alternate;

    left: 30%;
    top: 50%;

    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    transform: scale(0.5);
  }

  .x6 {
    -webkit-animation: animateBubble 5s linear infinite,
      sideWays 2s ease-in-out infinite alternate;
    -moz-animation: animateBubble 5s linear infinite,
      sideWays 2s ease-in-out infinite alternate;
    animation: animateBubble 5s linear infinite,
      sideWays 2s ease-in-out infinite alternate;

    left: 50%;
    top: 0;

    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    transform: scale(0.8);
  }

  .x7 {
    -webkit-animation: animateBubble 3.7s linear infinite,
      sideWays 2s ease-in-out infinite alternate;
    -moz-animation: animateBubble 3.7s linear infinite,
      sideWays 2s ease-in-out infinite alternate;
    animation: animateBubble 3.7s linear infinite,
      sideWays 2s ease-in-out infinite alternate;

    left: 65%;
    top: 70%;

    -webkit-transform: scale(0.4);
    -moz-transform: scale(0.4);
    transform: scale(0.4);
  }

  .x8 {
    -webkit-animation: animateBubble 4s linear infinite,
      sideWays 3s ease-in-out infinite alternate;
    -moz-animation: animateBubble 4s linear infinite,
      sideWays 3s ease-in-out infinite alternate;
    animation: animateBubble 4s linear infinite,
      sideWays 3s ease-in-out infinite alternate;

    left: 80%;
    top: 10%;

    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    transform: scale(0.3);
  }

  .x9 {
    -webkit-animation: animateBubble 6s linear infinite,
      sideWays 4s ease-in-out infinite alternate;
    -moz-animation: animateBubble 6s linear infinite,
      sideWays 4s ease-in-out infinite alternate;
    animation: animateBubble 6s linear infinite,
      sideWays 4s ease-in-out infinite alternate;

    left: 90%;
    top: 50%;

    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    transform: scale(0.6);
  }

  .x10 {
    -webkit-animation: animateBubble 5.2s linear infinite,
      sideWays 2s ease-in-out infinite alternate;
    -moz-animation: animateBubble 5.2s linear infinite,
      sideWays 2s ease-in-out infinite alternate;
    animation: animateBubble 5.2s linear infinite,
      sideWays 2s ease-in-out infinite alternate;

    left: 80%;
    top: 80%;

    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    transform: scale(0.3);
  }

  /* OBJECTS */

  .bubble {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: #729bff;

    height: 200px;
    position: absolute;
    width: 200px;
  }

  .bubble:after {
    content: "";
    height: 180px;
    left: 10px;
    position: absolute;
    width: 180px;
  }
}
