@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,600;1,400;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Francois+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Secular+One&display=swap");

@import "fontello/fontello-e988dd4d/css/fontello";

$menu-background: #1f1f22;
$bubble-background: #222225;
$cloud-background: #28282c;
$footer-bar-background: #2a2a33;
$name-color: #878fb8;
$comment-color: #898b97;

.middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.hideable {
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
}
